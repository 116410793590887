<template>
	<header>
		<nav class="navbar navbar-expand-lg navbar-dark">
			<div class="container-fluid">
				<router-link class="navbar-brand" to="/">Chris McGrane</router-link>
				<button
					class="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbar"
					aria-controls="navbar"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<img
						style="filter: invert(1)"
						src="icons/bars-solid.svg"
						width="20"
						height="20"
					/>
				</button>
				<div class="collapse navbar-collapse" id="navbar">
					<div class="navbar-nav ms-auto">
						<router-link
							v-for="(route, i) in routes"
							:tabindex="i + 1"
							:key="route.name"
							class="nav-link text-capitalize"
							aria-current="page"
							:to="route.name === 'home' ? '/' : route.name"
							:title="route.title"
							:data-text="route.title"
							>{{ route.name }}</router-link
						>
					</div>
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
export default {
	name: 'NavComponent',
	watch: {
		$route() {
			const navbar = document.querySelector('#navbar')
			navbar.classList.remove('show')
		}
	},
	computed: {
		routes() {
			return this.$router.options.routes
		}
	}
}
</script>