<template>
	<div class="container p-3">
		<div class="row py-3">
			<div class="col-12">
				<h2 class="text-light">Contact</h2>
			</div>
		</div>

		<div class="row mb-3">
			<div class="col-12 mb-3">
				<label class="d-block text-light"
					>Want to get in contact? Send me an email and I will reply
					quickly!</label
				>
				<a class="text-info" href="mailto:mail@chrismcgrane.com"
					>mail@chrismcgrane.com</a
				>

				<label class="d-block text-light mt-5"
					>Also, check out my
					<a
						class="text-info"
						target="_blank"
						href="https://github.com/notchris"
						>GitHub</a
					>
					for more projects.
				</label>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageContact',
	metaInfo: {
		title: 'Contact'
	},
	computed: {}
}
</script>