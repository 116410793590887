<template>
	<div class="home-wrapper">
		<div class="w-100 above p-5">
			<div class="row">
				<div class="col-12">
					<h1 class="text-white mb-3">
						<div class="text-primary home-title">Chris McGrane</div>
					</h1>
					<h4 class="text-light">
						Hello, I'm a full-stack developer from Connecticut, building
						interactive experiences for the web and beyond.
					</h4>
					<div class="mt-5">
						<router-link to="portfolio" class="btn btn-primary me-1">
							View Portfolio
						</router-link>
						<a
							target="_blank"
							href="https://github.com/notchris"
							class="btn btn-link"
						>
							<img
								style="filter: invert(1)"
								src="icons/github-brands.svg"
								width="20"
								height="20"
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
		<canvas class="banner" id="c"></canvas>
	</div>
</template>

<script>
import * as THREE from 'three'
export default {
	name: 'PageHome',
	metaInfo: {
		title: 'Home'
	},
	mounted() {
		let saturn

		const el = document.querySelector('.home-wrapper')
		const { width, height } = el.getBoundingClientRect()

		const getRandomParticelPos = (particleCount) => {
			const arr = new Float32Array(particleCount * 3)
			for (let i = 0; i < particleCount; i++) {
				arr[i] = (Math.random() - 0.5) * 10
			}
			return arr
		}

		// mouse
		let mouseX = 0
		let mouseY = 0
		document.addEventListener('mousemove', (e) => {
			mouseX = e.clientX
			mouseY = e.clientY
		})

		const main = () => {
			const canvas = document.getElementById('c')
			const renderer = new THREE.WebGLRenderer({
				canvas,
				antialias: true
			})

			renderer.setClearColor(new THREE.Color('#1c1624'))
			const scene = new THREE.Scene()

			// light source
			const color = 0xffffff
			const intensity = 1
			const light = new THREE.DirectionalLight(color, intensity)
			light.position.set(-3, 2, 4)
			scene.add(light)

			// camera
			const fov = 45,
				aspect = width / height,
				near = 0.1,
				far = 1000
			const camera = new THREE.PerspectiveCamera(fov, aspect, near, far)
			camera.position.z = 2
			camera.aspect = width / height
			camera.updateProjectionMatrix()
			renderer.setSize(width, height)
			// Geometry
			const geometrys = [new THREE.BufferGeometry(), new THREE.BufferGeometry()]

			geometrys[0].setAttribute(
				'position',
				new THREE.BufferAttribute(getRandomParticelPos(350), 3)
			)
			geometrys[1].setAttribute(
				'position',
				new THREE.BufferAttribute(getRandomParticelPos(1500), 3)
			)

			const loader = new THREE.TextureLoader()

			// material
			const materials = [
				new THREE.PointsMaterial({
					size: 0.05,
					map: loader.load('img/sp1.png'),
					transparent: true
					// color: "#ff0000"
				}),
				new THREE.PointsMaterial({
					size: 0.075,
					map: loader.load('img/sp2.png'),
					transparent: true
				})
			]

			const starsT1 = new THREE.Points(geometrys[0], materials[0])
			const starsT2 = new THREE.Points(geometrys[1], materials[1])
			scene.add(starsT1)
			scene.add(starsT2)

			const sunlight = new THREE.PointLight(0xffffff, 10)
			sunlight.castShadow = true
			sunlight.shadow.bias = -0.005
			sunlight.decay = 2
			scene.add(sunlight)

			const sphereGeo = new THREE.SphereGeometry(0.25, 64, 64)
			const planetMat = new THREE.MeshPhongMaterial({
				color: 0x0000ff,
				shininess: 0
			})

			const planetMesh = new THREE.Mesh(sphereGeo, planetMat)
			planetMesh.castShadow = true
			planetMesh.receiveShadow = true

			const ringGeo = new THREE.RingGeometry(0.3, 0.5, 64)

			const ringMat = new THREE.MeshStandardMaterial({
				side: THREE.DoubleSide,
				transparent: true,
				opacity: 0.5
			})

			const ringMesh = new THREE.Mesh(ringGeo, ringMat)
			ringMesh.castShadow = true
			ringMesh.receiveShadow = true

			saturn = new THREE.Group()
			saturn.position.z = 0
			saturn.rotation.x = 1.9
			saturn.rotation.y = 0.4

			saturn.add(planetMesh)
			saturn.add(ringMesh)
			scene.add(saturn)

			starsT1.position.x = 5
			starsT2.position.x = 3

			window.addEventListener('resize', () => {
				const { width, height } = el.getBoundingClientRect()
				camera.aspect = width / height
				camera.updateProjectionMatrix()
				renderer.setSize(width, height)
			})

			const render = () => {
				// time *= 0.001; //in seconds
				saturn.rotation.z += 0.001

				starsT1.position.x = mouseX * 0.0001
				starsT1.position.y = mouseY * -0.0001

				starsT2.position.x = mouseX * 0.0001
				starsT2.position.y = mouseY * -0.0001

				// Re-render the scene
				renderer.render(scene, camera)
				// loop
				requestAnimationFrame(render)
			}
			requestAnimationFrame(render)
		}
		main()
	}
}
</script>