<template>
	<div class="container p-3">
		<div class="row py-3">
			<div class="col-12">
				<h2 class="text-light">Skills</h2>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12 col-md-6 mb-3">
				<label class="d-block text-light">Languages / Frameworks</label>
				<div class="text-secondary">
					JavaScript, Vue.js, Node, Ember.js, HTML, CSS, Nim, PHP
				</div>
			</div>

			<div class="col-12 col-md-6">
				<label class="d-block text-light">Web Technologies / Libraries</label>
				<div class="text-secondary">
					Canvas, SVG, WebRTC, WebSockets, WebGL, Three.JS, PIXI
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12 col-md-6 mb-3">
				<label class="d-block text-light">Databases</label>
				<div class="text-secondary">SQL & SQLITE, MySQL, MongoDB</div>
			</div>

			<div class="col-12 col-md-6">
				<label class="d-block text-light">CMS Development</label>
				<div class="text-secondary">
					Wordpress, Directus, Ghost, nodeBB, WooCommerce, Stripe
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12 col-md-6 mb-3">
				<label class="d-block text-light"
					>Game Development & Physics Engines</label
				>
				<div class="text-secondary">
					Phaser, Matter.js, SAT, Planck.js, Box2D
				</div>
			</div>

			<div class="col-12 col-md-6">
				<label class="d-block text-light">Graphic Design</label>
				<div class="text-secondary">
					Photoshop, Illustrator, InDesign, Inkscape, Tiled, Blender
				</div>
			</div>
		</div>
		<div class="row mb-5">
			<div class="col-12 col-md-6">
				<label class="d-block text-light">Other</label>
				<div class="text-secondary">Marketing & Social Media, Security</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageSkills',
	metaInfo: {
		title: 'Skills'
	},
	computed: {}
}
</script>