import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import PageHome from '../views/PageHome.vue'
import PagePortfolio from '../views/PagePortfolio.vue'
import PageSkills from '../views/PageSkills.vue'
import PageContact from '../views/PageContact.vue'

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'home',
    title: 'Home',
    component: PageHome
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    title: 'Portfolio',
    component: PagePortfolio
  },
  {
    path: '/skills',
    name: 'skills',
    title: 'Skills',
    component: PageSkills
  },
  {
    path: '/contact',
    name: 'contact',
    title: 'Contact',
    component: PageContact
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
