<template>
	<div class="container p-3">
		<div class="row py-3">
			<div class="col-12">
				<h2 class="text-light">Portfolio</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-sm-4 col-md-3">
				<div class="text-secondary">Select Project:</div>
				<div class="d-block d-sm-none">
					<select class="form-control" @change="selectItem($event)">
						<option v-for="(item, i) in items" :key="`olist_${i}`" :value="i">
							{{ item.title }}
						</option>
					</select>
				</div>
				<nav class="nav flex-column d-none d-sm-block">
					<a
						v-for="(item, i) in items"
						:key="`list_${i}`"
						:class="[
							i === current ? 'active text-primary' : 'text-light',
							'nav-link py-1 px-0'
						]"
						href="#"
						@click.prevent="current = i"
						>{{ item.title }}</a
					>
				</nav>
			</div>
			<div class="col-12 col-sm-8 col-md-9 mt-3 mt-sm-0">
				<div class="info">
					<h4 class="text-light">
						{{ items[current].title }}
					</h4>
					<p class="text-secondary">
						{{ items[current].info }}
					</p>
					<div>
						<a
							class="btn btn-sm btn-secondary mb-2"
							:href="items[current].link"
							target="_blank"
						>
							<img
								style="filter: invert(1)"
								src="icons/github-brands.svg"
								width="20"
								height="20"
							/>
							View on GitHub</a
						>
					</div>
				</div>
				<img
					v-for="(image, j) in items[current].images"
					:key="'img_' + j + image"
					class="img-fluid border border-secondary mb-2"
					:src="image"
					:alt="`${items[current].title} - Preview ${j}`"
					:title="`${items[current].title}
				- ${j}`"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PagePortfolio',
	metaInfo: {
		title: 'Portfolio'
	},
	methods: {
		selectItem(event) {
			this.current = event.target.value
		}
	},
	data() {
		return {
			current: 0,
			items: [
				{
					title: 'Phaser / Planck Physics',
					images: ['img/0.gif'],
					info: `Integration of the Planck.js (Based on Box2d) physics engine with
				Phaser3, allowing for advanced collision detection, dynamic body
				types, trajectory projection, and more.`,
					link: 'https://github.com/notchris/phaser3-planck'
				},
				{
					title: '3D Modular Editor',
					images: ['img/12.png', 'img/22.png'],
					info: `A simple, multi-platform, 3D editor with support for importing custom models and materials.`,
					link: 'https://github.com/notchris/noteditor'
				},
				{
					title: 'Isometric Builder',
					images: ['img/30.png', 'img/21.png'],
					info: `An isometric builder concept for creating basic scenes with configurable props.`,
					link: 'https://github.com/notchris/isometric-grid'
				},
				{
					title: 'OSX Color Picker',
					images: ['img/32.png'],
					info: `An experiment with nodegui that adds a shortcut to open the native OSX color picker. Also adds a quick copy shortcut in RGB/Hex formats.`,
					link: 'https://github.com/notchris/macos-color-picker'
				},
				{
					title: 'Randcamp',
					images: ['img/rand.png'],
					info: `Generate a playlist of randomly selected tracks from newly released albums on BandCamp by genre. Built with Vue2 & Bootstrap4.`,
					link: 'https://github.com/notchris/RandCamp'
				},
				{
					title: 'Decent Patterns',
					images: ['img/13.png', 'img/14.png'],
					info: `Really simple SVG Patterns created using single unicode characters.`,
					link: 'https://github.com/notchris/decent-patterns'
				}
			]
		}
	},
	computed: {}
}
</script>