<template>
	<div id="app">
		<nav-component></nav-component>
		<router-view></router-view>
		<footer class="w-100 p-3 bg-black text-secondary text-center small">
			&copy;2022 Chris McGrane &ndash;
			<router-link to="contact">Contact</router-link>
		</footer>
	</div>
</template>
<script>
import NavComponent from './components/NavComponent.vue'
export default {
	components: {
		NavComponent
	}
}
</script>